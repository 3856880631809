import ready from '../mastodon/ready';  
import {logOut} from "../mastodon/utils/log_out";


class Bridge {  

	constructor() {    
		window.addEventListener('message', e => {      
			const data = e.data || {};        

			if (!window.parent ) {        
				return;      
			}        

			switch( data.type ) {
				case 'login': {
					window.document.getElementById('login_user_email').value = data.email;
					window.document.getElementById('login_user_password').value = data.password;
					window.document.getElementById('login_new_user').getElementsByClassName('btn button button-primary')[0].click();
					break;
				}
				case 'login2' : {
					window.document.getElementById('user_email').value = data.email;
					window.document.getElementById('user_password').value = data.password;
					window.document.getElementsByName('button')[0].click();
					break;
				}
				case 'logout': {
					logOut();
					break;
				}
				case 'regist': {
					window.document.getElementById('user_mpassnum').value = data.mpassnum;
					break;
				}
			}
		});        

		ready(() => {      
			window.parent.postMessage({        
				type : 'loadedPage',        
				url : location.href      
			}, '*');    
		});  
	}
}  

const bridge = new Bridge();
export default bridge;
